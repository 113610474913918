import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { sessionOpt } from '../../Data/jsonData/Academics/Academics';
import { ACADEMICS_ADD_SUBJECT } from '../../utils/Academics.apiConst';
import { POST_EXAM_ADD_MARKS, POST_EXAM_ADD_MARKS_BULK, PRE_EXAM_TIMETABLE, QPDS_QP_PATTERN } from '../../utils/Examination.apiConst';

function ModalAddMarks(props) {

    //Destructuring props
    const {
        addData,
        data,
        programOpt,
        collegeOpt,
        classOpt,
        semesterOpt,
        showCollege,
        showProgram,
        showMonth,
        showYear,
        setLoading,
        studentData,
        enrollmentStudents
    } = props;

    //Main obj
    const [user, setUser] = useState([])

    const [flag, setFlag] = useState(false)

    //Other States for data management
    const [subOpt, setSubOpt] = useState([])

    //states holding created time table data
    const [timeTableData, setTimeTableData] = useState([])

    const [addNew, setAddNew] = useState(2)

    const [addNewId, setAddNewId] = useState('')

    const [editData, setEditData] = useState()

    const [selectedSub, setSelectedSub] = useState('')



    const [addNewObj, setAddNewObj] = useState({
        question: '',
        marks: ''
    })

    const clearAddNewObj = () => {
        setAddNewObj({
            question: '',
            marks: ''
        })
    }

    const handleEdit = async()=>{
        const config = {
            method: 'put',
            url: `${POST_EXAM_ADD_MARKS}/${editData?.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data:{
                note:editData?.note,
                marks:editData?.marks,
                absent:editData?.absent,
            }
        }

        await axios(config)
            .then(res => {
                console.log(res);
                toast.success("Marks Updated")
                setAddNew(1)
            })
            .catch(err => {
                console.log(err);
            }
            )

    }



    ///////////Helping functions ///////////
    const handleChange = (id, name, value) => {
        console.log(id, name, value);

        const arr = []

        for (const el in user) {
            if (user[el]?.student_id == id) {
                const obj = {
                    ...user[el],
                    time_table_id: addNewId,
                    [name]: value
                }
                arr.push(obj)
            }
            else {
                arr.push(user[el])
            }
        }
        // for (const i in user) {
        //     if(user[i]?.data?.student_id==id){
        //         const obj = {
        //             ...user,
        //             [name]:value
        //         }
        //         user[i] = obj
        //     }
        // }

        setUser([...arr])
    }


    //////get Subject Data
    const getbasicData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: `${ACADEMICS_ADD_SUBJECT}?college_id=${showCollege}&semester_id=${addData?.semester_id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config)
            .then(res => {
                setSubOpt(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })

        setLoading(0)
    }

    //get time table data
    const getData = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: `${PRE_EXAM_TIMETABLE}?class_examination_id=${addData?.id}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            }
        }

        await axios(config)
            .then(res => {
                console.log(res);
                setTimeTableData(res.data.data)
            })
            .catch(err => {
                console.log(err);
            })
        setLoading(0)
    }


    const handleSubmit = async () => {
        for (const i of user) {
            if (!i?.marks && !i?.absent) return toast.error(`Missing marks for student (${i?.student_id})`)
            if (i?.absent && !i?.note) return toast.error(`Student (${i?.student_id}) is absent but deosn't added note`)
        }
        setLoading(1)

        const config = {
            method: 'post',
            url: POST_EXAM_ADD_MARKS_BULK,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
            data: { data: user }
        }

        await axios(config)
            .then(res => {
                toast.success('Success')
            })
            .catch(err => {
                toast.error('Something went wrong')
            })

        setLoading(0)
    }

    const getExamMarks = async () => {
        setLoading(1)
        const config = {
            method: 'get',
            url: `${POST_EXAM_ADD_MARKS}?time_table_id=${addNewId}`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem("UMS_auth")}`,
                "Content-Type": "application/json",
            },
        }
        await axios(config)
            .then(res => {
                console.log(res);
                if (res?.data?.length != 0) {
                    setUser(res.data)
                    console.log('here1');
                    setFlag(true)
                }
                else {
                    const arr = []
                    studentData.forEach(i => {
                        if (enrollmentStudents.find(s => s?.subject == selectedSub?.id && s?.student_id == i?.data?.student_id)) {
                            const obj = {
                                student_id: i?.data?.student_id
                            }
                            arr.push(obj)
                        }
                    });

                    console.log(arr);

                    setUser(arr)
                    setFlag(false)
                }
            })
            .catch(err => {
                console.log(err);
            })
        setLoading(0)
    }

    ////useEffects
    useEffect(() => {
        if (addData) {
            // setUser({
            //     class_examination_id: addData?.id,
            //     session_id: '',
            //     course_id: '',
            //     date: '',
            //     time_from: '',
            //     time_to: "",
            //     duration: "",
            //     max_marks: '',
            //     min_marks: ''
            // })
            getbasicData();
            getData();
        }
        setAddNew(0)
    }, [addData])

    useEffect(() => {
        if (addNew && addNewId) {
            getExamMarks()
        }
    }, [addNew, addNewId, studentData])


    return (
        <div
            className="modal fade"
            id="create"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalCenterTitle"
            aria-hidden="true"
        >
            <div className="modal-dialog mw-100 w-100 ">



                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
                            List
                        </h5>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    {
                        addNew == 2
                            ?
                            <div className="modal-body">
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <button className="btn btn-primary" onClick={()=>{setAddNew(1)}}>
                                            Back
                                        </button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Enrollment Number
                                            </label>
                                            <input
                                                type="text"
                                                  value={editData?.student_id}
                                                className="form-control"
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">
                                                Student Name
                                            </label>
                                            <input
                                                type="text"
                                                  value={studentData?.find(s => s?.data?.student_id == editData?.student_id)?.studentInfo?.name}
                                                className="form-control"
                                                readOnly={true}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">Attendance</label>
                                            <select
                                                name=""
                                                id=""
                                                value={editData?.absent}
                                                onChange={(e) => {
                                                    setEditData(prev => ({
                                                        ...prev,
                                                        absent: e.target.value
                                                    }))
                                                    if (e.target.value == 'true') {
                                                        setEditData(prev => ({
                                                            ...prev,
                                                            marks: 0
                                                        }))
                                                    }
                                                }}
                                                className='form-control'
                                            >
                                                <option value={'false'} selected>Present</option>
                                                <option value={'true'}>Absent</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">marks</label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                value={editData?.marks}
                                                onChange={(e) => {
                                                    setEditData(prev => ({
                                                        ...prev,
                                                        marks: e.target.value
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label htmlFor="">remarks</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={editData?.note}
                                                onChange={(e) => {
                                                    setEditData(prev => ({
                                                        ...prev,
                                                        note: e.target.value
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-4 mb-4">
                                    <div className="row">
                                        <div className="col-12  d-flex justify-content-end">
                                            <button 
                                            className="btn btn-success"
                                            onClick={handleEdit}
                                            >
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            addNew
                                ?
                                <div className="modal-body">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => { setAddNew(0) }}
                                    >
                                        Back
                                    </button>
                                    {
                                        !flag
                                            ?
                                            <>
                                                <button
                                                    className="btn btn-success float-right ml-3"
                                                    onClick={handleSubmit}
                                                >
                                                    Save Marks
                                                </button>
                                                <button
                                                    className="btn btn-success float-right"

                                                >
                                                    Approve
                                                </button>
                                            </>
                                            :
                                            null}
                                    <table className="table mt-4">
                                        <thead className='text-center'>
                                            <tr>
                                                <th>Sl No</th>
                                                <th>Reg. Number</th>

                                                <th>Student Name</th>
                                                <th>Subject Name</th>

                                                <th>Gender</th>
                                                <th>Attendance</th>
                                                <th>Marks</th>
                                                <th>Note</th>
                                                <th>action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            {
                                                user?.map((i, key) => (
                                                    <tr>
                                                        <td>{key + 1}</td>
                                                        <td>{i?.student_id}</td>
                                                        <td>{studentData?.find(s => s?.data?.student_id == i?.student_id)?.studentInfo?.name}</td>
                                                        <td>{selectedSub?.name}</td>
                                                        <td>{studentData?.find(s => s?.data?.student_id == i?.student_id)?.studentInfo?.gender}</td>
                                                        <td>
                                                            <input
                                                                type="checkbox"
                                                                name="absent"
                                                                id="absent"
                                                                value={i?.absent}
                                                                onChange={(e) => {
                                                                    if (e.target.checked == true) {
                                                                        handleChange(i?.student_id, 'marks', 0)
                                                                        console.log(e.target.checked);
                                                                    }
                                                                    handleChange(i?.student_id, e.target.name, e.target.checked)
                                                                }}
                                                            />
                                                            <label htmlFor="absent">Absent</label>
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="number"
                                                                name='marks'
                                                                id=''
                                                                className="form-control"
                                                                required
                                                                value={i?.marks}
                                                                onChange={(e) => {
                                                                    handleChange(i?.student_id, e.target.name, e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                name='note'
                                                                id='note'
                                                                className="form-control"
                                                                value={i?.note}
                                                                onChange={(e) => {
                                                                    handleChange(i?.student_id, e.target.name, e.target.value)
                                                                }}
                                                            />
                                                        </td>
                                                        <td className='d-flex align-items-center justify-content-center'>
                                                            <div
                                                                className='badge badge-soft-primary p-2 cursor-pointer'
                                                                data-target="#modal2"
                                                                data-toggle="modal"
                                                                onClick={()=>{setAddNew(2);setEditData(i)}}
                                                            >
                                                                <i className="ri ri-edit-line"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className="modal-body">

                                    <div className="row mt-4">
                                        <div className="col-lg-12  table-responsive ">
                                            <table className="table table-bordered nowrap table-hover " id="tab_logic">
                                                <thead>
                                                    <tr>
                                                        <th>Subjects</th>
                                                        <th>Date</th>
                                                        <th>Time From</th>
                                                        <th>Time To</th>


                                                        <th> Marks (Max..)</th>
                                                        <th>Marks (Min..)</th>
                                                        <th>Status</th>
                                                        <th >Enter Marks</th>
                                                    </tr>
                                                </thead>
                                                <tbody id="examsub">
                                                    {/* <div></div> */}
                                                    {
                                                        timeTableData?.map((i, key) => (
                                                            <tr key={key}>
                                                                <td>{subOpt?.find(s => s.id == i?.course_id)?.name}</td>
                                                                <td>{i?.date?.split("T")[0]}</td>
                                                                <td>{i?.time_from}</td>
                                                                <td>{i?.time_to}</td>

                                                                <td>{i?.max_marks}</td>
                                                                <td>{i?.min_marks}</td>
                                                                <td><span class="badge badge-soft-success mr-2">Marks Added</span> <span class="badge badge-soft-success">Approved</span> <span class="badge badge-soft-danger mr-2">Marks Not Added</span> <span class="badge badge-soft-warning">Pending</span></td>
                                                                <td className='d-flex align-items-center justify-content-center'>
                                                                    <button
                                                                        className="btn badge badge-light text-success p-1 mr-2"
                                                                        title="Add Marks"
                                                                        onClick={() => { setAddNew(1); setAddNewId(i?.id); setUser([]); setSelectedSub(subOpt?.find(s => s.id == i?.course_id)) }}
                                                                    >
                                                                        <i class="ri-add-line"></i> Add
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>

                    }
                    {/* <div className="modal-footer">
                        <button
                            type="button"
                            class="btn btn-primary"
                            data-dismiss="modal"
                            aria-label="Close"
                        >Save changes</button>
                    </div> */}
                </div>

                {/* /.modal-content */}
            </div>
            {/* /.modal-dialog */}
        </div>
    )
}

export default ModalAddMarks